import React from "react";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

interface ProjectLinkProps {
  github: string;
  liveUrl: string;
}

export const ProjectLinks = ({ github, liveUrl }: ProjectLinkProps) => (
  <div className="links flex">
    {github !== "" && (
      <a href={github}>
        <FontAwesomeIcon icon={faGithub} />
      </a>
    )}
    {liveUrl !== "" && (
      <a href={liveUrl}>
        <FontAwesomeIcon icon={faExternalLinkAlt} />
      </a>
    )}
  </div>
);
