import {
  faJsSquare,
  faReact,
  faAndroid,
} from "@fortawesome/free-brands-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-scroll";

export const About = () => (
  <div className="flex height-full" id="about">
    <div className="container">
      <div className="flex about">
        <h1 className="heading">
          <code className="pageno blue">1. </code>
          About
        </h1>
        <img
          src="https://res.cloudinary.com/dfqx6m8lw/image/upload/v1609250917/portfolio/Screenshot_2020-12-29_at_10.05.32_PM_pjalbu.png"
          alt=""
          className="profile"
        />
      </div>
      <p>Hello! I&apos;m Deborah, a App Developer based in Singapore.</p>
      <p>
        I was formerly a Full-Time Musician, but now I work with React Native
        (mostly), and Native code at Healint. We help 3,000,000 users (and
        counting!) manage their migraines.
      </p>
      <p>
        I love creating aesthetically pleasing and easy to use apps, with the
        ultimate goal being - to make life easier and more enjoyable for the
        people around me.
      </p>
      <p>Check out my socials on the bottom right on this page. (:</p>
      <div className="tech">
        <h5>Technologies I work with:</h5>
        <div className="skills">
          <div>
            <FontAwesomeIcon icon={faJsSquare} />
            <code>Typescript</code>
          </div>
          <div>
            <FontAwesomeIcon icon={faJsSquare} />
            <code>Javascript</code>
          </div>
          <div>
            <FontAwesomeIcon icon={faReact} />
            <code>ReactNative</code>
          </div>
          <div>
            <FontAwesomeIcon icon={faReact} />
            <code>React</code>
          </div>
          <div>
            <FontAwesomeIcon icon={faAndroid} />
            <code>Android</code>
          </div>
          {/* <div>
            <FontAwesomeIcon icon={faBootstrap} />
            <code>Bootstrap</code>
          </div> */}
          {/* <div>
            <FontAwesomeIcon icon={faNodeJs} />
            <code>Node.js/Express</code>
          </div>
          <div>
            <FontAwesomeIcon icon={faLeaf} />
            <code>MongoDB</code>
          </div> */}
          {/* <div>
            <FontAwesomeIcon icon={faHtml5} />
            <code>HTML</code>
          </div>
          <div>
            <FontAwesomeIcon icon={faCss3} />
            <code>CSS/SCSS</code>
          </div>
          <div>
            <FontAwesomeIcon icon={faPython} />
            <code>Django</code>
          </div>
          <div>
            <FontAwesomeIcon icon={faDatabase} />
            <code>PostgreSQL</code>
          </div>
          <div>
            <FontAwesomeIcon icon={faPython} />
            <code>Python</code>
          </div> */}
        </div>
      </div>
      <div id="scroll-icon">
        <Link to="projects">
          <FontAwesomeIcon icon={faChevronDown} />
        </Link>
      </div>
    </div>
  </div>
);
