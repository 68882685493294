import React from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-scroll";

export const Home = () => (
  <>
    <div className="flex height-full" id="home">
      <div className="container">
        <code>
          <span>Hello!</span> My name is
        </code>
        <h1 className="name">Deborah Jean Lee</h1>
        <code>I currently work at Healint as an</code>
        <h3>App Developer</h3>
        <code>using React Native.</code>
        <div id="scroll-icon">
          <Link to="about">
            <FontAwesomeIcon icon={faChevronDown} />
          </Link>
        </div>
      </div>
    </div>
  </>
);
