import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-scroll";
import { ICard, projects } from "../info/projects";
import { Card } from "./Card";

export const Projects = () => {
  const cards = projects.map((el: ICard) => (
    <Card project={el} key={`${el.title}`} />
  ));
  return (
    <div className="flex height-full" id="projects">
      <div className="container">
        <h1 className="heading">
          <code className="pageno orange">2.</code>
          Projects
        </h1>
        <div className="flex scroll">{cards}</div>
        <div id="scroll-icon" style={{ marginTop: "20px" }}>
          <Link to="contact">
            <FontAwesomeIcon icon={faChevronDown} />
          </Link>
        </div>
      </div>
    </div>
  );
};
