import React from "react";

export const Contact = () => (
  <div className="flex height-full" id="contact">
    <div className="container contact">
      <h1>
        <code className="pageno yellow">3.</code>
        Get In Touch
      </h1>
      <p className="mutedgrey">
        I&apos;m always looking to collaborate and to meet new people in the
        industry.
      </p>
      <p>
        <span className="orange"> Hit me up!</span> Let&apos;s see what we can
        create (:
      </p>
      <a href="mailto:debjeanlee.is@gmail.com">
        <div className="button">Say Hi!</div>
      </a>
    </div>
  </div>
);
