import React from "react";
import { ICard } from "../info/projects";
import { ProjectLinks } from "./ProjectLinks";

interface CardProps {
  project: ICard;
}

export const Card = ({ project }: CardProps) => {
  const tech = project.tech.map((el) => <li>{el}</li>);

  return (
    <div className="card-container">
      <div className="card">
        <div className="card-body">
          <div className="card-header flex">
            <h5>{project.title}</h5>
            <ProjectLinks github={project.github} liveUrl={project.liveUrl} />
          </div>
          <p>{project.desc}</p>
          <h6>Tech Stack:</h6>
          <ul>{tech}</ul>
        </div>
      </div>
      <div className="shadow" />
    </div>
  );
};
