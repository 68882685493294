export interface ICard {
  title: string;
  liveUrl: string;
  desc: string;
  tech: string[];
  github: string;
  longDesc: string;
}

export const projects: ICard[] = [
  {
    title: "Mastermind",
    liveUrl: "https://debjeanlee.github.io/mastermind/",
    desc: "A classic code-breaking boardgame for 2 players. First project ever completed on my own.",
    tech: ["HTML", "CSS", "Javascript"],
    github: "https://github.com/debjeanlee/mastermind",
    longDesc: "Fill in the long description",
  },
  {
    title: "Brew Dog Recipes",
    liveUrl: "https://debjeanlee.github.io/brewdogrecipes/",
    desc: "Made for Brew Dog beer brewing enthusiasts. Created for easy access to Brew Dog brewer recipes.",
    tech: ["React", "Bootstrap", "Axios", "CSS"],
    github: "https://github.com/debjeanlee/do-dog",
    longDesc: "Fill in the long description",
  },
  {
    title: "mumsworkshop",
    liveUrl: "http://mamasflowerslah.herokuapp.com",
    desc: "Groupwork. A shop that allows customers to customize their flower bouquets. Made with the intention of streamlining my mother's flower business. Lead designer.",
    tech: ["MERN", "Bootstrap", "SASS"],
    github: "https://github.com/eskaine/flowerShop",
    longDesc: "Fill in the long description",
  },
  {
    title: "Vier",
    liveUrl: "",
    desc: "A real-time restaurant service web app targeted at restaurants looking for ways to increase their service capabilities while reducing costs.",
    tech: ["MERN", "SASS", "Socket.IO"],
    github: "https://github.com/debjeanlee/vier",
    longDesc: "Fill in the long description",
  },
];
