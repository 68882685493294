import React from 'react';
import { About } from './components/About';
import { Contact } from './components/Contact';
import { Home } from './components/Home';
import { MainNav } from './components/MainNav';
import { Projects } from './components/Projects';
import SocialMedia from './components/SocialMedia';

export const App = () => (
  <div>
    <MainNav />
    <div className="flex">
      <div className="main">
        {/* <WorkInProgress /> */}
        <Home />
        <About />
        <Projects />
        <Contact />
      </div>
      <SocialMedia />
    </div>
  </div>
);
