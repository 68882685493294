import React from "react";
import { faAt, faCode, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-scroll";

export const MainNav = () => (
  <nav className="flex">
    <ul className="flex">
      <Link activeClass="active" to="home" spy smooth duration={500}>
        D
      </Link>
      <div>
        <div className="vline" />
      </div>
      <Link activeClass="active" to="about" spy smooth duration={500}>
        <FontAwesomeIcon icon={faAt} />
      </Link>
      <div>
        <div className="vline" />
      </div>
      <Link activeClass="active" to="projects" spy smooth duration={500}>
        <FontAwesomeIcon icon={faCode} />
      </Link>
      <div>
        <div className="vline" />
      </div>
      <Link activeClass="active" to="contact" spy smooth duration={500}>
        <FontAwesomeIcon icon={faPaperPlane} />
      </Link>
      {/* <div>
                        <div className="vline"></div>
                    </div> */}
      {/* <NavLink to="/music">
                    <FontAwesomeIcon icon={faMusic} />
                </NavLink> */}
    </ul>
  </nav>
);
