import {
  faFacebookSquare,
  faGithub,
  faInstagram,
  faLinkedin,
  faMedium,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function SocialMedia() {
  return (
    <div className="flex nav-right">
      <ul className="flex">
        <li className="email">debjeanlee.is@gmail.com</li>
        <a
          href="https://www.linkedin.com/in/debjeanlee/"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a
          href="https://github.com/debjeanlee"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faGithub} />
        </a>
        <a
          href="https://medium.com/@debjeanlee"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faMedium} />
        </a>
        <a
          href="https://instagram.com/debjeanlee"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          href="https://facebook.com/debjeanlee"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faFacebookSquare} />
        </a>
        <a
          href="https://www.youtube.com/user/debsjeans"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faYoutube} />
        </a>
        <div>
          <div className="vline" />
        </div>
      </ul>
    </div>
  );
}

export default SocialMedia;
